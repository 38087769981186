<template>
  <div v-loading="loading" class="opentix-coupon-edit">
    <div v-if="!loading" class="form-wrapper grid gap-[24px]">
      <OpentixInfoBlock :configData="configData" />
      <OpentixRuleBlock :configData="configData" />
      <OpentixSettingBlock :configData="configData" />
    </div>
    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import OpentixInfoBlock from './components/OpentixInfoBlock.vue'
import OpentixRuleBlock from './components/OpentixRuleBlock.vue'
import OpentixSettingBlock from './components/OpentixSettingBlock.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
// use
import { useRouter, useRoute } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { useBaseForm } from '@/use/useForm'
// other
import { get } from 'lodash'
import FormsContext from './formsContext'
import notifyMessage from '@/config/notifyMessage'
import { CreateCouponOpentix, UpdateCouponOpentix, FindCouponOpentix } from '@/api/opentix'

export default defineComponent({
  name: 'opentixCouponEdit',
  components: {
    OpentixInfoBlock,
    OpentixRuleBlock,
    OpentixSettingBlock,
    PageFixedFooter,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { loading } = useBaseForm()
    const configData = ref({})

    const onCancel = () => {
      router.push({
        name: 'OpentixCouponList',
      })
    }

    const computeSubmitData = () => {
      const allFormData = FormsContext.composFormData()
      // 自定義組裝最後要送出 api payload 的資料
      const { amount, editType } = allFormData.editData
      let sentLimit = null

      const firstSentLimit = parseInt(allFormData.firstSentLimit) === -1 ? null : parseInt(allFormData.firstSentLimit)
      if (get(configData.value, 'id')) { // 編輯模式
        sentLimit = parseInt(allFormData.lastCount)
        if (editType === 'add') sentLimit += parseInt(amount)
        else if (editType === 'minus') sentLimit -= parseInt(amount)
      } else { // 新增帶入首次發送數量
        sentLimit = firstSentLimit
      }
      const newData = {
        id: get(configData.value, 'id') || undefined,
        shopId: shopId.value,
        name: allFormData.name,
        sentLimit,
        firstSentLimit,
        notice: allFormData.notice,
        extraSetting: {
          openTixConfig: {
            startTime: allFormData.startTime,
            endTime: allFormData.endTime,
            currentDiscount: allFormData.currentDiscount ? parseFloat(allFormData.currentDiscount) : null,
            currentPrice: allFormData.currentPrice ? parseFloat(allFormData.currentPrice) : null,
            quantityPerMember: parseInt(allFormData.quantityPerMember),
            priceMinLimit: parseInt(allFormData.priceMinLimit),
            priceMaxLimit: parseInt(allFormData.priceMaxLimit),
            description: allFormData.description,
            note: allFormData.note,
            organizers: allFormData.codeType === 'organizers' ? allFormData.code : [],
            programs: allFormData.codeType === 'programs' ? allFormData.code : [],
            events: allFormData.codeType === 'events' ? allFormData.code : [],
          },
        },
        image: get(allFormData.image, 'id') || undefined,
      }

      return newData
    }
    // 送出表單
    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll() // 驗證所有區塊表單
      if (!allPass) return
      // console.log(submitData.value)
      const submitData = computeSubmitData()
      let apiMethod = CreateCouponOpentix
      let successMessage = notifyMessage.createSuccess
      if (get(configData.value, 'id')) {
        apiMethod = UpdateCouponOpentix
        successMessage = notifyMessage.updateSuccess
      }

      const handler = async (data, { emit, loading }) => {
        const [, err] = await apiMethod(data)
        loading.value = false
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message.success(successMessage)
        router.push({ name: 'OpentixCouponList' })
      }
      await handler(submitData, { emit, loading })
    }

    onMounted(async () => {
      loading.value = true

      if (route.params.id) {
        await simpleFetch(FindCouponOpentix, { shopId: shopId.value, id: route.params.id }, res => {
          configData.value = res
        })
      }

      loading.value = false
    })

    return {
      onCancel,
      onSubmit,
      configData,
      loading,
      FormsContext,
    }
  },
})
</script>
<style lang="postcss" scoped></style>
