<template>
  <div class="opentix-rule-block side-highlight-container">
    <p class="card-title">優惠券規則</p>
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="優惠券起迄時間" prop="date" required>
        <el-date-picker
          v-model="formData.date"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="結束時間"
          :disabled="isEdit"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item label="折抵類型" prop="discountType">
        <RadioBlock :value.sync="formData.discountType" :options="discountTypeOptions" />
      </el-form-item>
      <el-form-item v-if="formData.discountType === 'amount'" label="折抵金額" prop="currentPrice">
        <el-input v-model="formData.currentPrice" placeholder="請輸入折抵金額" :disabled="isEdit" /><i class="icon__percentage">元</i>
      </el-form-item>
      <el-form-item v-if="formData.discountType === 'percent'" label="折抵比例" prop="currentDiscount">
        <el-input v-model="formData.currentDiscount" placeholder="請輸入折抵比例，例如：10" :disabled="isEdit" /><i class="icon__percentage">%</i>
      </el-form-item>
      <el-form-item label="單次最多可領取張數" prop="quantityPerMember">
        <el-input v-model="formData.quantityPerMember" placeholder="請輸入數量" :disabled="isEdit" />
      </el-form-item>
      <el-form-item label="是否限制優惠券可適用的票價區間" prop="priceLimit">
        <RadioBlock :value.sync="formData.priceLimit" :options="priceLimitTypeOptions" />
      </el-form-item>
      <el-form-item v-if="formData.priceLimit === 'yes'" label="設定票價適用金額區間">
        <PriceRange :value.sync="formData.priceRange" :disabled="isEdit" />
      </el-form-item>
      <el-form-item label="選擇代碼類型" prop="codeType">
        <el-select
          v-model="formData.codeType"
          placedholder="請選擇"
          :disabled="isEdit"
        >
          <el-option
            v-for="option in codeTypeOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="填寫代碼" prop="code">
        <el-select
          v-model="formData.code"
          multiple
          filterable
          allow-create
          default-first-option
          clearable
          :no-data-text="get(codeTypeOptions, `[${formData.codeType}].placeholder`)"
          :no-match-text="get(codeTypeOptions, `[${formData.codeType}].placeholder`)"
          :placeholder="formData.codeType === null ? '請先選擇代碼類型' : get(codeTypeOptions, `[${formData.codeType}].placeholder`)"
          :disabled="formData.codeType === null || isEdit"
        />
      </el-form-item>
      <!-- <el-form-item
        v-for="(field, index) in filteredFormFields"
        :key="`${field.prop}_${index}`"
        :label="field.label"
        :prop="field.prop === 'priceRange' ? null : field.prop"
        :required="field.required"
      >
        <component
          :is="field.component"
          v-model="formData[field.prop]"
          :name="field.name"
          :value.sync="formData[field.prop]"
          :options="field.options"
          :disabled="get(field, 'attrs.disabled')"
          :placeholder="get(field, 'attrs.placeholder')"
          :multiple="get(field, 'attrs.multiple')"
          :filterable="get(field, 'attrs.filterable')"
          :allow-create="get(field, 'attrs.allowCreate')"
          :default-first-option="get(field, 'attrs.defaultFirstOption')"
          :clearable="get(field, 'attrs.clearable')"
          :no-data-text="get(field, 'attrs.noDataText')"
          :no-match-text="get(field, 'attrs.noMatchText')"
        > -->
      <!-- <template v-if="field.component === 'el-radio-group'">
            <el-radio
              v-for="option in field.options"
              :key="option.value"
              :label="option.value"
              :disabled="option.disabled"
            >
              {{ option.label }}
            </el-radio>
          </template> -->
      <!-- <template v-if="field.component === 'el-select'">
            <el-option
              v-for="option in field.options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled"
            />
          </template>
        </component>
        <i v-if="field.prop === 'currentPrice'" class="icon__percentage">元</i>
        <i v-if="field.prop === 'currentDiscount'" class="icon__percentage">%</i>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch, nextTick, ref } from 'vue'
import { get, set } from 'lodash'
import { useBaseForm, onFormRulesChangeClearValidate, mappingSyncFormData, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules, integerRules, maxArrayRules } from '@/validation'
import FormsContext from '../formsContext'
import PriceRange from './PriceRange'
import RadioBlock from './RadioBlock'
// import SelectBlock from './SelectBlock'

export default defineComponent({
  name: 'OpentixRuleBlock',
  components: {
    PriceRange,
    RadioBlock,
    // SelectBlock,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: [],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    const avatarChanged = ref(false)
    const uploadDialog = ref(false)
    const codeTypeOptions = ref({
      organizers: { label: '限定主辦單位(主辦單位代碼)', value: 'organizers', placeholder: '請輸入主辦單位代碼' },
      programs: { label: '限定節目(節目代碼)', value: 'programs', placeholder: '請輸入節目代碼' },
      events: { label: '限定場次(場次代碼)', value: 'events', placeholder: '請輸入場次代碼' },
    })
    const isEdit = computed(() => !!get(props.configData, 'id'))
    const discountTypeOptions = computed(() => [
      {
        label: '依比例折抵',
        value: 'percent',
        disabled: isEdit.value,
      },
      {
        label: '依金額折抵',
        value: 'amount',
        disabled: isEdit.value,
      },
    ])
    const priceLimitTypeOptions = computed(() => [
      {
        label: '有適用金額限制',
        value: 'yes',
        disabled: isEdit.value,
      },
      {
        label: '無限制',
        value: 'no',
        disabled: isEdit.value,
      },
    ])
    // const formFields = computed(() => [
    //   {
    //     label: '優惠券起迄時間',
    //     name: 'date',
    //     prop: 'date',
    //     component: 'el-date-picker',
    //     attrs: {
    //       type: 'datetimerange',
    //       format: 'yyyy-MM-dd HH:mm:ss',
    //       rangeSeparator: '至',
    //       startPlaceholder: '開始時間',
    //       endPlaceholder: '結束時間',
    //       disabled: !!get(props.configData, 'id'),
    //     },
    //     required: true,
    //   },
    //   {
    //     label: '折抵類型',
    //     name: 'discountType',
    //     prop: 'discountType',
    //     component: RadioBlock,
    //     options: [
    //       {
    //         label: '依比例折抵',
    //         value: 'percent',
    //         disabled: !!get(props.configData, 'id'),
    //       },
    //       {
    //         label: '依金額折抵',
    //         value: 'amount',
    //         disabled: !!get(props.configData, 'id'),
    //       },
    //     ],
    //   },
    //   {
    //     label: '折抵金額',
    //     name: 'currentPrice',
    //     prop: 'currentPrice',
    //     component: 'el-input',
    //     attrs: { placeholder: '請輸入折抵金額', disabled: !!get(props.configData, 'id') },
    //     condition: formData => formData.discountType === 'amount',
    //   },
    //   {
    //     label: '折抵比例',
    //     name: 'currentDiscount',
    //     prop: 'currentDiscount',
    //     component: 'el-input',
    //     attrs: { placeholder: '請輸入折抵比例，例如：10', disabled: !!get(props.configData, 'id') },
    //     condition: formData => formData.discountType === 'percent',
    //   },
    //   {
    //     label: '單次最多可領取張數',
    //     name: 'quantityPerMember',
    //     prop: 'quantityPerMember',
    //     component: 'el-input',
    //     attrs: {
    //       placeholder: '請輸入數量',
    //       disabled: !!get(props.configData, 'id'),
    //     },
    //   },
    //   {
    //     label: '是否限制優惠券可適用的票價區間',
    //     name: 'priceLimit',
    //     prop: 'priceLimit',
    //     component: RadioBlock,
    //     options: [{
    //       label: '有適用金額限制',
    //       value: 'yes',
    //       disabled: !!get(props.configData, 'id'),
    //     },
    //     {
    //       label: '無限制',
    //       value: 'no',
    //       disabled: !!get(props.configData, 'id'),
    //     }],
    //   },
    //   {
    //     label: '設定票價適用金額區間',
    //     name: 'PriceRange',
    //     prop: 'priceRange',
    //     component: PriceRange,
    //     condition: formData => formData.priceLimit === 'yes',
    //     disabled: !!get(props.configData, 'id'),
    //   },
    //   {
    //     label: '選擇代碼類型',
    //     name: 'codeType',
    //     prop: 'codeType',
    //     component: 'el-select',
    //     options: codeTypeOptions.value,
    //     attrs: {
    //       disabled: !!get(props.configData, 'id'),
    //     },
    //   },
    //   {
    //     label: '填寫代碼',
    //     name: 'code',
    //     prop: 'code',
    //     component: SelectBlock,
    //     attrs: formData.codeType === null
    //       ? {
    //         disabled: formData.codeType === null,
    //         placeholder: '請先選擇代碼類型',
    //       }
    //       : {
    //         multiple: true,
    //         filterable: true,
    //         allowCreate: true,
    //         defaultFirst: true,
    //         clearable: true,
    //         noDataText: get(codeTypeOptions.value, `[${formData.codeType}].placeholder`),
    //         noMatchText: get(codeTypeOptions.value, `[${formData.codeType}].placeholder`),
    //         placeholder: get(codeTypeOptions.value, `[${formData.codeType}].placeholder`),
    //         disabled: !!get(props.configData, 'id'),
    //       },
    //   },
    // ])
    // const filteredFormFields = computed(() => {
    //   if (!formData) {
    //     return []
    //   }
    //   return formFields.value.filter(field => !field.condition || field.condition(formData))
    // })
    /** 初始化表單值 */
    initFormData({
      date: null,
      discountType: 'percent',
      currentPrice: null,
      currentDiscount: null,
      quantityPerMember: null,
      priceLimit: 'yes',
      priceRange: {
        priceMinLimit: null,
        priceMaxLimit: null,
      },
      codeType: null,
      code: [],
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        date: [noEmptyRules()],
        discountType: [noEmptyRules()],
        currentPrice: [noEmptyRules(), integerRules()],
        currentDiscount: [noEmptyRules(), integerRules()],
        quantityPerMember: [noEmptyRules(), integerRules()],
        priceLimit: [noEmptyRules()],
        priceRange: {
          priceMinLimit: [noEmptyRules()],
          priceMaxLimit: [noEmptyRules()],
        },
        codeType: [noEmptyRules()],
        // code: [noEmptyRules()],
      }
      if (formData.codeType !== null) rules.code = [noEmptyRules(), maxArrayRules(40)]
      // if (formData.priceLimit === 'yes') {
      //   rules.priceRange = {
      //     priceMinLimit: [noEmptyRules()],
      //     priceMaxLimit: [noEmptyRules()],
      //   }
      // }
      // handle custom rules here
      // if (...) rules.xxx = [...]
      return rules
    })

    /** 同步表單資料 */
    const syncFormData = (data) => {
      set(formData, 'date', [get(data, 'extraSetting.openTixConfig.startTime'), get(data, 'extraSetting.openTixConfig.endTime')])
      set(formData, 'discountType', get(data, 'extraSetting.openTixConfig.currentPrice') ? 'amount' : 'percent')
      set(formData, 'currentPrice', get(data, 'extraSetting.openTixConfig.currentPrice'))
      set(formData, 'currentDiscount', get(data, 'extraSetting.openTixConfig.currentDiscount'))
      set(formData, 'quantityPerMember', get(data, 'extraSetting.openTixConfig.quantityPerMember'))
      const priceMinLimit = get(data, 'extraSetting.openTixConfig.priceMinLimit')
      const priceMaxLimit = get(data, 'extraSetting.openTixConfig.priceMaxLimit')

      set(formData, 'priceLimit', priceMinLimit || priceMaxLimit ? 'yes' : 'no')
      set(formData, 'priceRange.priceMinLimit', priceMinLimit)
      set(formData, 'priceRange.priceMaxLimit', priceMaxLimit)
      const codeData = {
        type: get(data, 'extraSetting.openTixConfig.organizers.length') ? 'organizers'
          : get(data, 'extraSetting.openTixConfig.events.length') ? 'events'
            : get(data, 'extraSetting.openTixConfig.programs.length') ? 'programs' : null,
        data: get(data, 'extraSetting.openTixConfig.organizers.length') ? get(data, 'extraSetting.openTixConfig.organizers')
          : get(data, 'extraSetting.openTixConfig.events.length') ? get(data, 'extraSetting.openTixConfig.events')
            : get(data, 'extraSetting.openTixConfig.programs.length') ? get(data, 'extraSetting.openTixConfig.programs') : [],
      }
      set(formData, 'codeType', get(codeData, 'type'))
      set(formData, 'code', get(codeData, 'data'))
      // -> 如果有原始資料 key 與 formData 的 key 命名不同時：
      // mappingSyncFormData({
      //   'extraSetting.openTixConfig.quantityPerMember': 'quantityPerMember',
      // }, data, formData)
      // -> 如果都一樣，可以簡單使用：
      // simpleSyncFormData(data, formData)
      // console.log('rule syncFormData', data)
    }

    const compactData = computed(() => {
      const data = {
        quantityPerMember: get(formData, 'quantityPerMember'),
        discountType: get(formData, 'discountType'),
        currentPrice: get(formData, 'currentPrice'),
        currentDiscount: get(formData, 'currentDiscount'),
        priceLimit: get(formData, 'priceLimit'),
        startTime: get(formData, 'date[0]'),
        endTime: get(formData, 'date[1]'),
        priceMinLimit: get(formData, 'priceRange.priceMinLimit'),
        priceMaxLimit: get(formData, 'priceRange.priceMaxLimit'),
        codeType: get(formData, 'codeType'),
        code: get(formData, 'code') || [],
      }
      return data
    })

    watch(compactData, () => {
      FormsContext.setFormData('rule', compactData.value)
    })

    // 需要時再使用：
    // onFormRulesChangeClearValidate(formRef, formRules)
    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('rule', formRef.value)
      if (get(props.configData, 'id')) {
        syncFormData(props.configData)
      }
    })

    return {
      formRef,
      formData,
      formRules,
      loadImg,
      uploadDialog,
      getImage,
      // filteredFormFields,
      codeTypeOptions,
      get,
      discountTypeOptions,
      priceLimitTypeOptions,
      isEdit,
    }
  },
})
</script>

<style lang="postcss" scoped>
.opentix-rule-block {
  padding-left: 29px !important;
}
.form-relative-label {
  @apply relative;
}
::v-deep .el-form-item__content, ::v-deep .el-date-editor {
  @apply w-[560px]
}
.icon__percentage {
  position: absolute;
  top: 50%;
  right: 11px;
  color: #767676;
  transform: translateY(-50%);
}
::v-deep .el-select__tags {
  @apply top-[3px] translate-y-0
}
::v-deep .el-input--suffix, ::v-deep .el-select {
  @apply h-auto
}
</style>
