<template>
  <div>
    <div class="radio-block">
      <el-radio-group v-model="syncData">
        <el-radio
          v-for="option in options"
          :key="option.value"
          :label="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'RadioBlock',
  props: {
    value: String,
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'value', emit)
    return { syncData }
  },
})

</script>
