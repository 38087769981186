<template>
  <div>
    <div class="price-range">
      <p>最低</p>
      <el-form-item prop="priceRange.priceMinLimit">
        <el-input v-model="syncData.priceMinLimit" :disabled="disabled" placeholder="請輸入" />
      </el-form-item>
      <p>元 ～ 最高</p>
      <el-form-item prop="priceRange.priceMaxLimit">
        <el-input v-model="syncData.priceMaxLimit" :disabled="disabled" placeholder="請輸入" />
      </el-form-item>
      <p>元</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from 'vue'
import { useVModel } from '@vueuse/core'
export default defineComponent({
  name: 'PriceRange',
  props: {
    value: Object,
    disabled: Boolean,
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'value', emit)
    return { syncData }
  },
})

</script>

<style lang="postcss" scoped>
.price-range {
  @apply flex flex-row gap-[20px];
  ::v-deep .el-form-item__content, .el-input {
    @apply !w-[100px];
  }
}
</style>
