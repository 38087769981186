<template>
  <BaseDialog
    :show="true"
    width="600px"
    :title="title"
    btn1="確認"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item label="操作類型" prop="editType">
          <el-radio-group v-model="formData.editType">
            <el-radio label="add">增加</el-radio>
            <el-radio label="minus">扣除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="數量" prop="amount">
          <el-input v-model="formData.amount" />
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { exportFormatOptions } from '@/config/export'
import { couponTypeConfig } from '@/config/coupon'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules, isDigitRules } from '@/validation'

export default defineComponent({
  name: 'OpentixCouponEditModal',
  components: { BaseDialog },
  props: { title: String },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    initFormData({
      editType: 'add',
      amount: null,
    })
    const formRules = {
      editType: [noEmptyRules()],
      amount: [noEmptyRules(), isDigitRules()],
    }

    const onConfirm = () => {
      formRef.value.validate((valid) => {
        if (valid) emit('confirm', formData)
      })
    }
    return {
      onConfirm,
      formData,
      exportFormatOptions,
      formRef,
      formRules,
      couponTypeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
