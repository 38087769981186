import { render, staticRenderFns } from "./OpentixInfoBlock.vue?vue&type=template&id=64044d16&scoped=true"
import script from "./OpentixInfoBlock.vue?vue&type=script&lang=js"
export * from "./OpentixInfoBlock.vue?vue&type=script&lang=js"
import style0 from "./OpentixInfoBlock.vue?vue&type=style&index=0&id=64044d16&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64044d16",
  null
  
)

export default component.exports